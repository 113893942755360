<template>
  <vx-card class="insert-payment main-box md:w-1/2 top-zero-radius">

    <div class="insert-payment-fields">
      <div class="insert-payment-field mt-3">
        <span class="label">{{ $t('treasury.payment.labels.type') }}</span>
        <custom-select :default="newDocument.type"
                       :options="typeOptions"
                       v-model="newDocument.type"/>
      </div>

      <div class="insert-payment-field">
        <custom-validate-input :value="newDocument.user.name"
                               :classes="{'w-full': true}"
                               :label="$t('treasury.payment.labels.user')"
                               align="right"/>

        <font-awesome-icon class="suggest-modal-button"
                           icon="tasks"
                           @click="suggestUserPromptStatus = true"/>

        <font-awesome-icon class="new-suggest-modal-button"
                           icon="plus"
                           @click="newSuggestUserPromptStatus = true"/>

        <!-- users List prompt -->
        <vs-prompt
          class="very-big-prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="suggestUserPromptStatus"
          @close="suggestUserPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('selectUsers')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('users.user.list.title')}}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="suggestUserPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content py-2 px-3">
            <template>
              <keep-alive>
                <select-users :multi-select="false" @selected="handleSuggestUserSelected($event)"/>
              </keep-alive>
            </template>
          </div>

        </vs-prompt>

        <!-- insert new user prompt -->
        <vs-prompt
          class="prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="newSuggestUserPromptStatus"
          @close="newSuggestUserPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('insertUserBTN')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('users.user.insert.title') }}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="newSuggestUserPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content">
            <template>
              <keep-alive>
                <insert-user :in-modal="true" @inserted="handleNewUserInserted($event)" />
              </keep-alive>
            </template>
            <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
          </div>

        </vs-prompt>
      </div>

      <div class="insert-payment-field mt-3" v-if="checkUserPermissions('payment.dynamicDate')">
        <custom-date-picker-input :label="$t('treasury.payment.labels.date')"
                                  type="datetime"
                                  :input-format="$validator('moment.dateTime')"
                                  :is-empty="true"
                                  :max="currentDate"
                                  :placeholder="$t('treasury.payment.placeholder.date')"
                                  :invalid-text="$t('treasury.payment.validators.dateHelp', {date: `${currentDate.split(' ')[1]} ${currentDate.split(' ')[0]}`})"
                                  :regex="dateTimeRegex"
                                  v-model="newDocument.date" />
      </div>

      <div class="insert-payment-field mt-3">
        <span class="label">{{ $t('treasury.payment.labels.source') }}</span>
        <custom-select :default="newDocument.paymentFrom"
                       :options="paymentFromOptions"
                       v-model="newDocument.paymentFrom"/>
      </div>

      <div class="insert-payment-field mt-3"
           v-if="newDocument.paymentFrom.value.type === 'bank'">
        <span class="label">{{ $t('treasury.payment.labels.paymentType') }}</span>
        <custom-select :default="newDocument.paymentType"
                       :options="paymentTypes"
                       @suggest:selected="handlePaymentTypeSelected"
                       v-model="newDocument.paymentType"/>
      </div>

      <div class="insert-payment-field mt-3" v-if="['ctc', 'iban'].indexOf(newDocument.paymentType.value) > -1">
        <custom-price-input :label="$t('treasury.payment.labels.bankWage')"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            v-model="newDocument.bankWage"/>
      </div>

      <div class="insert-payment-field mt-3" v-if="newDocument.paymentFrom.value.id > 0">
        <custom-price-input :label="newDocument.paymentFrom.value.type === 'bank' ? $t('treasury.payment.labels.bankBalance') : $t('treasury.payment.labels.cashBoxBalance')"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            :disabled="true"
                            :value="newDocument.paymentFrom.value.balance"/>
      </div>
    </div>

    <div class="insert-payment-fields mt-3">
      <div class="insert-payment-field">
        <custom-price-input :label="`${$t('treasury.payment.labels.price')}`"
                            :classes="{'w-full': true}"
                            v-model="newDocument.price"/>
      </div>

      <div class="insert-payment-field">
        <custom-validate-textarea :label="`${$t('treasury.payment.labels.description')}`"
                                  :classes="{'w-full': true}"
                                  :rtl="true"
                                  align="right"
                                  :is-empty="false"
                                  v-model="newDocument.description"/>
      </div>
    </div>

    <!-- type selector -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title=""
      @close="$router.replace({name: 'treasuryPayment'})"
      :active.sync="selectPaymentTypeStatusPrompt">

      <div class="prompt-content p-3" style="padding-top: 10px !important;">

        <button class="select-type-button"
                @click="handleSelectPaymentType(1)">
          <custom-icon class="ml-2"
                       icon="MONEY"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('treasury.payment.paymentTypes.customer') }}
          </span>

          <vs-spacer/>
        </button>

        <button class="select-type-button mt-3"
                @click="handleSelectPaymentType(2)">
          <custom-icon class="ml-2"
                       icon="CREDIT_CARD"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('treasury.payment.paymentTypes.supplier') }}
          </span>

          <vs-spacer/>
        </button>
      </div>
    </vs-prompt>
    <!-- /type selector -->

    <vs-button id="insertDocumentBTN" @click="insertDocument" class="useral-action-button"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment-jalaali'
import CustomSelect from '@/components/customSelect/customSelect'
import {getBanks} from '@/http/requests/banks'
import {getCashBoxes} from '@/http/requests/cashBoxes'
import {insertPayment} from '@/http/requests/documents'
import {getUser} from '../../../../../http/requests/users/users'
import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
import SelectUsers from '../../../customers/select/selectUsers'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import CustomPriceInput from '../../../../../components/customInput/customPriceInput'
import CustomValidateTextarea from '../../../../../components/customInput/customValidateTextarea'
import {checkUserPermissions, getTimeFromServer} from "../../../../../assets/js/functions";
import InsertUser from "../../../customers/insert/insertUser";
import CustomDatePickerInput from "../../../../../components/customInput/customDatePickerInput";
import {getTreasuryRequirements} from "../../../../../http/requests/requirements";

export default {
  name: 'insertPayment',
  metaInfo () {
    return {
      title: this.$t('treasury.payment.insert.title')
    }
  },
  components: {
    CustomDatePickerInput,
    InsertUser,
    CustomValidateTextarea, CustomPriceInput, CustomIcon, SelectUsers, CustomValidateInput, CustomSelect},
  data () {
    return {
      suggestUserPromptStatus: false,
      newSuggestUserPromptStatus: false,
      selectPaymentTypeStatusPrompt: true,
      negativeTreasuryStatus: false,
      dateTimeRegex: this.$validator('regex.dateTime'),
      currentDate: '',
      type: 0,
      typeOptions: [
        {
          label: this.$t('treasury.payment.paymentTypes.customer'),
          value: 1
        },
        {
          label: this.$t('treasury.payment.paymentTypes.supplier'),
          value: 2
        }
      ],
      paymentFromOptions: [],
      paymentTypes: [
        // {
        //   label: this.$t('treasury.payment.bankTypes.pos'),
        //   value: 1
        // },
        {
          label: this.$t('treasury.payment.bankTypes.card'),
          value: 'ctc'
        },
        {
          label: this.$t('treasury.payment.bankTypes.sheba'),
          value: 'iban'
        }
      ],
      bankWages: {
        cardToCard: 0,
        iban: 0
      },
      newDocument: {
        date: {
          value: '',
          isValid: true
        },
        type: {
          label: '-',
          value: 0
        },
        price: {},
        description: {},
        user: {
          name: {},
          id: 0
        },
        cash: {
          label: '-',
          value: 0
        },
        bank: {
          label: '-',
          value: 0
        },
        paymentFrom: {
          label: '-',
          value: {
            id: 0,
            type: '',
            balance: {
              value: '0',
              isValid: true
            }
          }
        },
        paymentType: {
          label: '-',
          value: 0
        },
        bankWage: {
          value: '',
          isValid: true
        }
      },
      actions: [
        {
          toolbar: [
            {
              id: 'insertDocumentBTN',
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryPayment'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 m-0 mx-auto')
    }, 50)
    this.currentDate = moment(getTimeFromServer()).format(this.$validator('moment.dateTime'))

    this.getTreasuryRequirements()
    this.handleShowPaymentFromOptions()
    this.getUser()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    handleSelectPaymentType (type) {
      this.newDocument.type = this.typeOptions[type - 1]
      this.selectPaymentTypeStatusPrompt = false
    },
    handleShowPaymentFromOptions () {
      if (checkUserPermissions('cash.show')) {
        this.getCashes()
      }
      if (checkUserPermissions('bank.show')) {
        this.getBanks()
      }
    },
    getUser () {
      if (this.$route.query.hasOwnProperty('user')) {
        getUser(this.$route.query.user).then(response => {
          const user = response.data.data
          this.newDocument.user = {
            name: {
              value: `${user.name} ${user.family}`,
              isValid: true
            },
            id: user.id
          }
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      }
    },
    getTreasuryRequirements() {
      getTreasuryRequirements().then(response => {
        const app = response.data.data
        if (app.treasury_with_negative_balance === '0') {
          this.negativeTreasuryStatus = false
        } else {
          this.negativeTreasuryStatus = true
        }
        this.bankWages.cardToCard = app.card_to_card_wage || ''
        this.bankWages.iban = app.iban_wage || ''
      })
    },
    getBanks () {
      getBanks().then(response => {
        const banks = response.data.data
        banks.forEach((bank) => {
          this.paymentFromOptions.push({
            label: bank.name,
            value: {
              id: bank.id,
              type: 'bank',
              balance: {
                value: bank.balance,
                isValid: true
              }
            }
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    getCashes () {
      getCashBoxes().then(response => {
        const cashes = response.data.data
        cashes.forEach((cash) => {
          this.paymentFromOptions.push({
            label: cash.name,
            value: {
              id: cash.id,
              type: 'cash',
              balance: {
                value: cash.balance,
                isValid: true
              }
            }
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    handleNewUserInserted (user) {
      this.newDocument.user = {
        id: user.id,
        name: {
          value: `${user.name} ${user.family}`,
          isValid: true
        }
      }
      this.newSuggestUserPromptStatus = false
    },
    handleSuggestUserSelected (list) {
      let user = {}
      list.forEach((data) => {
        if (!data.hasOwnProperty('show') || data.show) {
          user = {
            id: data.id,
            name: {
              value: data.name,
              isValid: true
            }
          }
        }
      })
      this.newDocument.user = user
      this.suggestUserPromptStatus = false
    },
    handlePaymentTypeSelected () {
      if (this.newDocument.paymentType.value === 'ctc') {
        this.newDocument.bankWage.placeholder = this.bankWages.cardToCard
      } else if (this.newDocument.paymentType.value === 'iban') {
        this.newDocument.bankWage.placeholder = this.bankWages.iban
      }
    },
    insertDocument () {
      // if (!this.negativeTreasuryStatus && parseInt(this.newDocument.price.value) > this.newDocument.paymentFrom.value.balance.value) {
      //   this.$vs.notify({
      //     title: this.$t('alert.error.title'),
      //     text: this.$t('treasury.payment.insert.notifications.price'),
      //     time: 2400,
      //     color: 'danger',
      //     icon: 'icon-alert-circle',
      //     iconPack: 'feather'
      //   })
      //   return false
      // }
      const payment = {
        user_id: this.newDocument.user.id,
        type: this.newDocument.type.value,
        description: this.newDocument.description.value || '',
        price: (this.newDocument.price.value || 0).toString().replaceAll(',', '')
      }

      switch (this.newDocument.paymentFrom.value.type) {
      case 'cash':
        if (this.newDocument.paymentFrom.value.id !== 0) payment.cash_id = this.newDocument.paymentFrom.value.id
        break

      case 'bank':
        if (this.newDocument.paymentFrom.value.id !== 0) {
          payment.bank_id = this.newDocument.paymentFrom.value.id
          payment.payment_type = this.newDocument.paymentType.value
          payment.wage = this.newDocument.bankWage.value || this.newDocument.bankWage.placeholder || '0'
        }
        break
      }

      if (checkUserPermissions('payment.dynamicDate') && this.newDocument.date.isValid) {
        payment.date = this.newDocument.date.value
      }

      if (payment.user_id === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.payment.insert.notifications.invalidUser'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (parseInt(payment.price) === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.payment.insert.notifications.invalidPrice'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (payment.description.length === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.payment.insert.notifications.invalidDescription'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newDocument.paymentFrom.value.id === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.payment.insert.notifications.invalidPaymentFrom'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type === 2 && this.newDocument.paymentType.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.payment.insert.notifications.invalidPaymentType'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (!this.newDocument.date.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.payment.validators.date'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      // if (moment(this.newDocument.date.value).isAfter(this.currentDate)) {
      //   this.$vs.notify({
      //     title: this.$t('alert.error.title'),
      //     text: this.$t('treasury.payment.validators.future'),
      //     time: 2400,
      //     color: 'danger',
      //     icon: 'icon-alert-circle',
      //     iconPack: 'feather'
      //   })
      //   return false
      // }

      insertPayment(payment).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.payment.insert.notifications.insert.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        if (this.$route.query.hasOwnProperty('user')) {
          this.$router.back()
        } else {
          this.$router.push({name: 'treasuryPayment'})
        }
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          switch (error.response.status) {
          case 406:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.payment.insert.notifications.lowBalance'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break

          default:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.payment.insert.notifications.insert.error'),
              time: 2400,
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.insert-payment {

  .insert-payment-fields {

    .insert-payment-field {
      position: relative;

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: 35px;
        top: 5px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border: 1px solid #00bcd4;
        border-radius: .5rem;
        color: #00bcd4;
        background: white;
        cursor: pointer;
      }

      .new-suggest-modal-button {
        right: 5px;
        border: 1px solid #28C76F;
        color: #28C76F;
      }

      span.label, .input-label {
        font-size: 12px;
        position: absolute;
        padding: 0 5px;
        left: 7px;
        top: -10px;
        z-index: 12000;
        background: linear-gradient(180deg, #f8f8f8, #ffffff);
      }

      .useral-custom-element-select {

        .selected {
          height: 35px;
          line-height: 35px;
          font-size: 13px;
        }

        .items {
          z-index: 12100;

          div {
            font-size: 13px;
            height: 35px;
            line-height: 35px;
          }
        }
      }
    }

    &:first-child {
      background: #cecece22;
      border: 1px solid #cecece;
      border-radius: .5rem;
      padding: 0 10px;

      span.label, .custom-validate-input .input-label {
        backdrop-filter: sepia(1);
      }
    }
  }
}
</style>
